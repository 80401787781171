import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';

import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private amplifyService: AuthenticatorService, private router: Router) { }

  authStatus(): string {
    return this.amplifyService.authStatus;
  }

  signOut() {
    this.amplifyService.signOut();
    this.router.navigate(['/login']);
  }

  user() {
    return this.amplifyService.user.username;

  }

}
