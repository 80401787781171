import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { QueriesService } from '../../services/queries.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-userform',
  templateUrl: './userform.component.html',
  styleUrl: './userform.component.scss'
})
export class UserformComponent implements OnInit {

  @Input()
  userId!: string;
  userFormSubmitted = false;


  userInfoForm: FormGroup = new FormGroup({
    'firstName': new FormControl('', Validators.required),
    'lastName': new FormControl('', Validators.required),
    'email': new FormControl('', Validators.required),
    'phone': new FormControl(''),
    'group': new FormControl('', Validators.required),
    'role': new FormControl('', Validators.required)
  });
  groups: any[] = [];
  companies: any[] = [];
  user!: any;
  roles = ['PARTNER', 'ADMIN'];
  showUpdateUser = false;
  constructor(private queryService: QueriesService, private toastr: ToastrService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private router: Router) {

  }

  ngOnInit(): void {
    if (this.userId) {
      this.spinner.show('loadingFormSpinner');
      this.queryService.getCognitoUser(this.userId).subscribe({
        next: data => {
          this.showUpdateUser = true;
          this.initData(data.UserAttributes);
        },
        error: err => {
          this.spinner.hide('loadingFormSpinner');
          this.toastr.error('An error occurred loading the user.')
        }
      });
    } else {
      this.initData(undefined);
      this.spinner.show('loadingFormSpinner');
    }
    this.queryService.getGroups().subscribe({
      next: data => {
        this.spinner.hide('loadingFormSpinner');
        this.groups = data.Items;

      },
      error: err => {
        this.spinner.hide('loadingFormSpinner');
        this.toastr.error('There was an error loading the form');
      }
    });
    const regexPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    this.userInfoForm.get('email')?.valueChanges.subscribe(val => {
      const valid = regexPattern.test(val);
      if (valid && !this.userId) {
        this.checkEmailExists(val)
      }
    });
  }

  initData(d: any) {
    const id = d?.find((e: any) => e.Name == "sub");
    const name = d?.find((e: any) => e.Name == "name");
    const nameParts = name?.Value.trim().split(' ');
    const firstName = nameParts?.slice(0, -1).join(' ');
    let lastName = '';
    if (nameParts) {
      lastName = nameParts[nameParts.length - 1]
    }
    const email = d?.find((e: any) => e.Name == "email");
    const phone = d?.find((e: any) => e.Name == "phone_number");
    const companyName = d?.find((e: any) => e.Name == "custom:companyName");
    const companyId = d?.find((e: any) => e.Name == "custom:companyId");
    const groupName = d?.find((e: any) => e.Name == "custom:groupName");
    const groupid = d?.find((e: any) => e.Name == "custom:groupId");
    const acceptedTerms = d?.find((e: any) => e.Name == "custom:acceptedTerms");
    const role = d?.find((e: any) => e.Name == "custom:role");



    const group = this.groups.find(el => el.id == groupid.Value);
    const company = group?.companies.find((el: any) => el.id == companyId.Value);
    this.user = {
      cognitoId: id?.Value, firstName: firstName || '', lastName: lastName || '', email: email?.Value, phone: phone?.Value || '', role: role?.Value || 'PARTNER', group: group || '', company: company || '', acceptedTerms: acceptedTerms?.Value || ''
    };
    this.userInfoForm.controls['firstName'].setValue(this.user.firstName || '');
    this.userInfoForm.controls['lastName'].setValue(this.user.lastName || '');
    this.userInfoForm.controls['email'].setValue(this.user.email || '');
    this.userInfoForm.controls['phone'].setValue(this.user.phone || '');
    this.userInfoForm.controls['group'].setValue(this.user.group || '');
    this.userInfoForm.controls['role'].setValue(this.user.role || 'PARTNER');

    if (this.userId) {
      this.userInfoForm.controls['email'].disable()
    }
  }

  refreshCompanies() {
    const selectedGroup = this.userInfoForm.get('group')?.value;
    this.companies = selectedGroup.companies;

  }

  updateUser() {
    this.userFormSubmitted = true;
    if (this.userInfoForm.valid) {
      this.spinner.show('UpdatingUser');
      let formObj = this.userInfoForm.getRawValue();
      this.queryService.updateCognitoUser(formObj).subscribe({
        next: data => {
          this.spinner.hide('UpdatingUser');
          this.toastr.success('User updated successfully');
          this.router.navigate(['/users']);
        },
        error: err => {
          this.spinner.hide('UpdatingUser');
          this.toastr.error('An error occurred updating the user');
        }
      });
    }
  }

  addUser() {
    this.userFormSubmitted = true;
    if (this.userInfoForm.valid) {
      this.spinner.show('addingUser');
      let formObj = this.userInfoForm.getRawValue();
      this.queryService.addAdminCognitoUser(formObj).subscribe({
        next: data => {
          this.spinner.hide('addingUser');
          this.toastr.success('User added successfully');
          this.router.navigate(['/users']);
        },
        error: err => {
          this.spinner.hide('addingUser');
          this.toastr.error('An error occurred adding the user');
        }
      });
    }
  }

  checkEmailExists(email: string) {
    this.queryService.checkEmailExistsInCognito(email).subscribe({
      next: rep => {
        if (rep.emailExists) {
          this.toastr.error(`The patient with email, ${email}, already exists in the authentication system. Please enter a different email`);
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }
      },
      error: err => {
        this.spinner.hide("newPatientSpinner");
        this.toastr.error(`The patient with email, ${email}, already exists in the authentication system. Please enter a different email`);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
    });
  }
}
