<div class="nav-wrapper">
  <img src="https://test.medsdirectrx.com/assets/top_banner.svg" height="" />
  <div class="d-flex justify-content-center">
    <ul class="list-group list-group-horizontal">
      <li class="list-group-item" [routerLink]="['/dashboard']">Dashboard</li>
      <li class="list-group-item" [routerLink]="['/enroll']">Enroll</li>
      <li class="list-group-item" [routerLink]="['/order-history']">Order History</li>
      <li class="list-group-item" [routerLink]="['/profile']">Profile</li>
    </ul>
  </div>
</div>