export const dartCareStatusLevels: { [key: string]: any } = {
  '1': 'Received',
  '2': 'Patient Engagement',
  '3': 'Provider Engagement',
  '4': 'Intake',
  '5': 'Dispensing',
  '6': 'Invoicing',
  '7': 'Complete',
  '8': 'Research',
  '9': 'On hold'
}


