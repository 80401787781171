import { Component } from '@angular/core';

@Component({
  selector: 'app-patient-navbar',
  templateUrl: './patient-navbar.component.html',
  styleUrl: './patient-navbar.component.scss'
})
export class PatientNavbarComponent {

}
