import { Amplify } from '@aws-amplify/core';
import { assertTokenProviderConfig, AuthAction } from '@aws-amplify/core/internals/utils';
import { signUp as signUp$1 } from '../utils/clients/CognitoIdentityProvider/index.mjs';
import { assertValidationError } from '../../../errors/utils/assertValidationError.mjs';
import { AuthValidationErrorCode } from '../../../errors/types/validation.mjs';
import { getRegion } from '../utils/clients/CognitoIdentityProvider/utils.mjs';
import { toAttributeType } from '../utils/apiHelpers.mjs';
import { setUsernameUsedForAutoSignIn, setAutoSignInStarted, isSignUpComplete, isAutoSignInStarted, autoSignInUserConfirmed, handleCodeAutoSignIn, autoSignInWhenUserIsConfirmedWithLink } from '../utils/signUpHelpers.mjs';
import { getAuthUserAgentValue } from '../../../utils/getAuthUserAgentValue.mjs';
import { setAutoSignIn } from './autoSignIn.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * Creates a user
 *
 * @param input - The SignUpInput object
 * @returns SignUpOutput
 * @throws service: {@link SignUpException } - Cognito service errors thrown during the sign-up process.
 * @throws validation: {@link AuthValidationErrorCode } - Validation errors thrown either username or password
 *  are not defined.
 * @throws AuthTokenConfigException - Thrown when the token provider config is invalid.
 */
async function signUp(input) {
    const { username, password, options } = input;
    const authConfig = Amplify.getConfig().Auth?.Cognito;
    const signUpVerificationMethod = authConfig?.signUpVerificationMethod ?? 'code';
    const { clientMetadata, validationData, autoSignIn } = input.options ?? {};
    assertTokenProviderConfig(authConfig);
    assertValidationError(!!username, AuthValidationErrorCode.EmptySignUpUsername);
    assertValidationError(!!password, AuthValidationErrorCode.EmptySignUpPassword);
    const signInServiceOptions = typeof autoSignIn !== 'boolean' ? autoSignIn : undefined;
    const signInInput = {
        username,
        options: signInServiceOptions,
    };
    // if the authFlowType is 'CUSTOM_WITHOUT_SRP' then we don't include the password
    if (signInServiceOptions?.authFlowType !== 'CUSTOM_WITHOUT_SRP') {
        signInInput.password = password;
    }
    if (signInServiceOptions || autoSignIn === true) {
        setUsernameUsedForAutoSignIn(username);
        setAutoSignInStarted(true);
    }
    const clientOutput = await signUp$1({
        region: getRegion(authConfig.userPoolId),
        userAgentValue: getAuthUserAgentValue(AuthAction.SignUp),
    }, {
        Username: username,
        Password: password,
        UserAttributes: options?.userAttributes && toAttributeType(options?.userAttributes),
        ClientMetadata: clientMetadata,
        ValidationData: validationData && toAttributeType(validationData),
        ClientId: authConfig.userPoolClientId,
    });
    const { UserSub, CodeDeliveryDetails } = clientOutput;
    if (isSignUpComplete(clientOutput) && isAutoSignInStarted()) {
        setAutoSignIn(autoSignInUserConfirmed(signInInput));
        return {
            isSignUpComplete: true,
            nextStep: {
                signUpStep: 'COMPLETE_AUTO_SIGN_IN',
            },
            userId: UserSub,
        };
    }
    else if (isSignUpComplete(clientOutput) && !isAutoSignInStarted()) {
        return {
            isSignUpComplete: true,
            nextStep: {
                signUpStep: 'DONE',
            },
            userId: UserSub,
        };
    }
    else if (!isSignUpComplete(clientOutput) &&
        isAutoSignInStarted() &&
        signUpVerificationMethod === 'code') {
        handleCodeAutoSignIn(signInInput);
    }
    else if (!isSignUpComplete(clientOutput) &&
        isAutoSignInStarted() &&
        signUpVerificationMethod === 'link') {
        setAutoSignIn(autoSignInWhenUserIsConfirmedWithLink(signInInput));
        return {
            isSignUpComplete: false,
            nextStep: {
                signUpStep: 'COMPLETE_AUTO_SIGN_IN',
                codeDeliveryDetails: {
                    deliveryMedium: CodeDeliveryDetails?.DeliveryMedium,
                    destination: CodeDeliveryDetails?.Destination,
                    attributeName: CodeDeliveryDetails?.AttributeName,
                },
            },
            userId: UserSub,
        };
    }
    return {
        isSignUpComplete: false,
        nextStep: {
            signUpStep: 'CONFIRM_SIGN_UP',
            codeDeliveryDetails: {
                deliveryMedium: CodeDeliveryDetails?.DeliveryMedium,
                destination: CodeDeliveryDetails?.Destination,
                attributeName: CodeDeliveryDetails?.AttributeName,
            },
        },
        userId: UserSub,
    };
}

export { signUp };

