<div class="card ">
  <div class="card-body ">
    <form class="row " [formGroup]="userInfoForm">



      <p class="form-label">First Name <span class="red">*</span> </p>
      <div class=" row mb-3">
        <div class="col-3">
          <input type="text" class="form-control" placeholder="First Name" formControlName="firstName" />
          <span *ngIf="!userInfoForm.get('firstName')?.valid && userFormSubmitted" class="help-block">First
            name required</span>
        </div>

        <div class="col-3">
          <input type="text" class="form-control" placeholder="Last Name" formControlName="lastName" />
          <span *ngIf="!userInfoForm.get('lastName')?.valid && userFormSubmitted" class="help-block">Last
            name required</span>
        </div>

      </div>
      <div class=" row mb-3">
        <div class="col-6">
          <p class="form-label">Email <span class="red">*</span> </p>
          <input type="email" class="form-control" placeholder="Email" formControlName="email" />
          <span *ngIf="!userInfoForm.get('email')?.valid && userFormSubmitted" class="help-block">Enter
            email</span>
        </div>
        <div class="col-6">
          <p class="form-label">Role <span class="red">*</span> </p>
          <select name="role" id="role" formControlName="role" class="form-select" >
            @for (role of roles; track role) {
            <option [ngValue]="role" selected="selected">{{role}}</option>
            }
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p class="form-label mt-3">Partner</p>

          <select name="state" id="group" formControlName="group" class="form-select"
            (ngModelChange)="refreshCompanies()">
            <option value="" selected="selected">Select a partner</option>
            @for (group of groups; track group.name) {
            <option [ngValue]="group" selected="selected">{{group.name}}</option>
            }
          </select>

          <span *ngIf="!userInfoForm.get('group')?.valid && userFormSubmitted" class="help-block">Select a
            Partner</span>
        </div>

      </div>
      <div class="row">
        <div class="d-flex justify-content-end align-items-center mt-3">
          <!-- <button *ngIf="showUpdateUser" type="button" class="btn btn-outline-primary"
          (click)="updateUser()">Update</button>
        <button *ngIf="!showUpdateUser" type="button" class="btn btn-outline-primary"
          (click)="addUser()">Submit</button> -->
          <p-button pButton pRipple *ngIf="showUpdateUser" label="Update" class="p-button-success"
            (click)="updateUser()"></p-button>
          <p-button pButton pRipple *ngIf="!showUpdateUser" label="Submit" class="p-button-success"
            (click)="addUser()"></p-button>
        </div>
      </div>
    </form>
  </div>
</div>
<ngx-spinner name="loadingFormSpinner" bdColor="rgba(51,51,51,0.0)" color="#003BA4" [fullScreen]="true"
  type="ball-scale-pulse" size="medium">
  <h4 class="spinner-text">Loading Form</h4>
</ngx-spinner>

<ngx-spinner name="addingUser" bdColor="rgba(51,51,51,0.0)" color="#003BA4" [fullScreen]="false" type="ball-scale-pulse"
  size="medium">
  <h4 class="spinner-text">Adding User</h4>
</ngx-spinner>

<ngx-spinner name="UpdatingUser" bdColor="rgba(51,51,51,0.0)" color="#003BA4" [fullScreen]="false"
  type="ball-scale-pulse" size="medium">
  <h4 class="spinner-text">Updating User</h4>
</ngx-spinner>
