<footer class="footer">
  <div class="foot-wrapper">
    <img src="https://test.medsdirectrx.com/assets/bottom_banner.svg" height="" class="banner" *ngIf="isPatient" />
    <div class="container-fluid">
      <nav>
        <ul>
          <li>
          </li>
          <li>
          </li>
          <li>

          </li>
        </ul>
      </nav>
      <div class="copyright" [ngClass]="{'copyright-patient':isPatient}">
        &copy;
        {{test | date: 'yyyy'}}, Meds Direct Rx
      </div>
    </div>
  </div>
</footer>