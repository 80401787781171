import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { PhoneMaskDirective } from './phone-mask.directive';

@NgModule({
  imports: [],
  declarations: [PhoneMaskDirective],
  exports: [PhoneMaskDirective]

})
export class SharedModule { }
