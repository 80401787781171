import { jwtDecode } from "jwt-decode";
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from "../services/auth.service";
import { from, switchMap } from "rxjs";
import { fetchAuthSession } from "aws-amplify/auth";

@Injectable()
export class TermsGuard {
  constructor(public auth: AuthService, public router: Router) { }

  async canActivate() {
    const user = await fetchAuthSession();
    const token = user.tokens?.idToken?.payload||{};
    if(!user.tokens) {
      this.router.navigate(['/login']);
      return false;
    }
    const acceptedTerms = localStorage.getItem('accpetedTerms');
    if (token['custom:acceptedTerms'] == 'no' || (!token['custom:acceptedTerms'] && acceptedTerms != 'yes')) {
     this.router.navigate(['/terms']);
      return false;
    }
    return true

  }

}
