export const routes = [
  { path: 'dashboard', title: 'Dashboard', icon: 'design_app', class: '', roles: [{ role: 'ADMIN', isVisible: true }, { role: 'PATIENT', isVisible: true }, { role: 'PARTNER', isVisible: true }] },
  { path: 'registrations', title: 'Registrations', icon: 'business_badge', class: '', roles: [{ role: 'ADMIN', isVisible: true }, { role: 'PARTNER', isVisible: true }] },
  { path: 'enrollments', title: 'Shoppers', icon: 'shoppers-icon', class: '', roles: [{ role: 'ADMIN', isVisible: true }, { role: 'PARTNER', isVisible: false }] },
  { path: 'payers', title: 'Payers', icon: 'business_bank', class: '', roles: [{ role: 'ADMIN', isVisible: true }, { role: 'PARTNER', isVisible: true }] },
  { path: 'companies', title: 'Companies', icon: 'office-icon', class: '', roles: [{ role: 'ADMIN', isVisible: true }, { role: 'PARTNER', isVisible: true }] },
  { path: 'patients', title: 'Patients', icon: 'users_circle-08', class: '', roles: [{ role: 'ADMIN', isVisible: true }, { role: 'PARTNER', isVisible: true }] },
  { path: 'orders', title: 'Orders', icon: 'health_ambulance', class: '', roles: [{ role: 'ADMIN', isVisible: true }, { role: 'PARTNER', isVisible: true }] },
  { path: 'groups', title: 'Partners', icon: 'users_circle-08', class: '', roles: [{ role: 'ADMIN', isVisible: true }] },
  { path: 'products', title: 'Products', icon: 'design_bullet-list-67', class: '', roles: [{ role: 'ADMIN', isVisible: true }, { role: 'PARTNER', isVisible: true }] },
  { path: 'pricing-request', title: 'Pricing Request', icon: 'pricing-request-icon', class: '', roles: [ { role: 'PARTNER', isVisible: true }] },
  { path: 'users', title: 'Users', icon: 'users_single-02', class: '', roles: [{ role: 'ADMIN', isVisible: true }] },
  { path: 'enroll', title: 'Enroll Patient', icon: 'users_single-02', class: '', roles: [{ role: 'ADMIN', isVisible: false }, { role: 'PARTNER', isVisible: false }] },
  { path: 'enroll', title: 'Enroll', icon: 'users_single-02', class: '', roles: [{ role: 'PATIENT', isVisible: true }, { role: '', isVisible: true }] },
  { path: 'profile', title: 'Profile', icon: 'users_single-02', class: '', roles: [{ role: 'PATIENT', isVisible: true }] },
  { path: 'order-history', title: 'Order History', icon: 'users_single-02', class: '', roles: [{ role: 'PATIENT', isVisible: true }, { role: 'ADMIN', isVisible: false }, { role: 'PARTNER', isVisible: false }] },
  { path: 'new-group', title: 'New Partner', icon: 'users_single-02', class: '', roles: [{ role: 'ADMIN', isVisible: false }] },
  { path: 'new-patient', title: 'New Patient', icon: 'users_single-02', class: '', roles: [{ role: 'ADMIN', isVisible: false }, { role: 'PARTNER', isVisible: false }] },
  { path: 'new-payer', title: 'New Payer', icon: 'users_single-02', class: '', roles: [{ role: 'ADMIN', isVisible: false }, { role: 'PARTNER', isVisible: false }] },
  { path: 'new-registrations', title: 'New Registrations', icon: 'users_single-02', class: '', roles: [{ role: 'ADMIN', isVisible: false }, { role: 'PARTNER', isVisible: false }] },
  { path: 'group', title: 'Partner', icon: 'users_single-02', class: '', roles: [{ role: 'ADMIN', isVisible: false }] },
  { path: 'patient', title: 'Patient', icon: 'users_single-02', class: '', roles: [{ role: 'ADMIN', isVisible: false }, { role: 'PARTNER', isVisible: false }] },
  { path: 'user', title: 'User', icon: 'users_single-02', class: '', roles: [{ role: 'ADMIN', isVisible: false }] },
  { path: 'payer', title: 'Payer', icon: 'users_single-02', class: '', roles: [{ role: 'ADMIN', isVisible: false }, { role: 'PARTNER', isVisible: false }] },
  { path: 'new-company', title: 'New Company', icon: 'users_single-02', class: '', roles: [{ role: 'ADMIN', isVisible: false }, { role: 'PARTNER', isVisible: false }] },
  { path: 'company', title: 'Company', icon: 'users_single-02', class: '', roles: [{ role: 'ADMIN', isVisible: false }, { role: 'PARTNER', isVisible: false }] },
  { path: 'guides', title: 'User Guides', icon: 'users_single-02', class: '', roles: [{ role: 'ADMIN', isVisible: false }, { role: 'PARTNER', isVisible: true }] },
  { path: 'reports', title: 'Reports', icon: 'business_chart-bar-32', class: '', roles: [{ role: 'ADMIN', isVisible: true }, { role: 'PARTNER', isVisible: true }] },
];
