import { group } from '@angular/animations';
import { QueriesService } from './../../services/queries.service';
import { Component, OnInit } from '@angular/core';
import { routes } from '../../routes';
import { fetchAuthSession } from 'aws-amplify/auth';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  roles: any[]
}
export const ROUTES: RouteInfo[] = routes;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[] = [];
  user: any;
  userRole: string = '';
  group:any;
  constructor(private queriesService:QueriesService) { }

  async ngOnInit() {
    const ses = await fetchAuthSession();
    this.user =ses.tokens?.idToken?.payload||{}
    this.userRole = this.user['custom:role']
    this.menuItems = ROUTES.filter(menuItem => menuItem);

    if(this.userRole=='PARTNER') {
      this.queriesService.getGroup(this.user['custom:groupId']).subscribe({
        next:data=>{
          if (data.Items.length > 0 && !data.Items[0].useMedsDirectProgram){
            this.menuItems = ROUTES.filter(menuItem => menuItem.path!='registrations');
          }
        },
        error: err=>{
          console.log(err);
        }
      });


    }

  }

  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  };

  userCanView(link: RouteInfo) {
    for (var i = 0; i < link.roles.length; i++) {
      if (link.roles[i].role == this.userRole && link.roles[i].isVisible) {
        return true;
      }
    }
    return false;
  }
}
