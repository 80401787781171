import { Component, OnInit } from '@angular/core';
import { fetchAuthSession } from 'aws-amplify/auth';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  test: Date = new Date();
  user: any;
  isPatient = true;
  constructor() { }

  async ngOnInit() {
    const ses = await fetchAuthSession();
     this.user = ses.tokens?.idToken?.payload;

    if (this.user['custom:role'] != 'PATIENT') {
      this.isPatient = false;
    }
  }

}
