export const environment = {
  production: false,
  //baseUrl: 'https://z4yfvr70kf.execute-api.us-east-2.amazonaws.com',
  baseUrl: 'https://test-api.medsdirectrx.com',
  stepsUrl: 'https://9zoak5e4nc.execute-api.us-east-2.amazonaws.com/test',
  stepsExecUrl: 'https://7ba3likpr8.execute-api.us-east-2.amazonaws.com',
  cognitoUrl: 'https://medsdirectrx.auth.us-east-2.amazoncognito.com',
  redirectUri: 'https://dbs9adv3ir9zi.cloudfront.net',
  clientId: 'bsn5i5cht9v602npje62bi7vu'
};
