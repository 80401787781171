import { QueriesService } from './../../services/queries.service';
import { Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { dartCareStatusLevels } from '../../core/dart-care-status-levels';
import { fetchAuthSession } from 'aws-amplify/auth';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrl: './order-details.component.scss'
})
export class OrderDetailsComponent implements OnInit {
  @Input()
  order: any;
  invoiceLink: string = '';
  invoiceNumber: any;
  user: any;
  isAdmin = false;
  trackingInputValue: string = '';
  shippingDateInputValue:string = '';
  deliveryDateInputValue: string = '';
  orderStatusInputValue = '';
  qbInvoiceInputValue = '';
  showSubmit: boolean = false;
  showStatusSubmit = false;
  showQBInvoiceIdSubmit = false;

  constructor(private clipboard: Clipboard, private queriesService: QueriesService, private toastr: ToastrService, private spinner: NgxSpinnerService) {

  }
  async ngOnInit(): Promise<void> {
    const ses = await fetchAuthSession();
    this.user = ses.tokens?.idToken?.payload || {}
    if (this.user['custom:role'] == 'ADMIN') {
      this.isAdmin = true;
    }
    this.orderStatusInputValue = this.statusAccess(this.order.dartCareStatus);
    this.qbInvoiceInputValue = this.order.invoiceId;
  }


  copyPhone(e: any, text: string) {
    e.stopPropagation();
    console.log('order id ', text);

    this.clipboard.copy(text)
  }
  copyOrderId(e: any, text: string) {
    e.stopPropagation();
    console.log('order id ', text);

    this.clipboard.copy(text)
  }
  copyInvoiceNumber(e: any, text: string) {
    e.stopPropagation();
    console.log('invoice id ', text);
    this.clipboard.copy(text)
  }
  copyInvoiceLink(e: any, text: string) {
    e.stopPropagation();
    console.log('invoice link ', text);
    this.invoiceLink = text;
    this.clipboard.copy(text)
  }

  statusAccess(str: string) {
    if (!isNaN(Number(str)) && str.trim() !== '') {
      return dartCareStatusLevels[str];
    } else {
      return str;
    }
  };

  updateOrderStatus() {
    const data = { id: this.order.id, orderId: this.order.orderId, patientEmail: this.order.patientEmail, status: this.orderStatusInputValue };
    this.queriesService.updateOrderStatus(data).subscribe({
      next: (res) => {
        this.toastr.success('Status updated successfully');
        this.showStatusSubmit = false;
      },
      error: (err) => {
        this.toastr.error('Error updating status');
      }
    });
  }

  getQTY(qty: string) {
    let num = '30 Days';
    switch (qty.toLowerCase()) {
      case '90_day':
        num = '90 Days';
        break;
      case '84_day':
        num = '84 Days';
        break;
      case '60_day':
        num = '60 Days';
        break;
      case '30_day':
        num = '30 Days';
        break;
    }
    return num;
  }

  onTrackingInputChange() {
    this.showSubmit = this.trackingInputValue.trim().length > 0;
  }
  shippingDateInputChange() {
    this.showSubmit = this.shippingDateInputValue.trim().length > 0;
  }
  saveShippingDate() {
    const data = { id: this.order.id, orderId: this.order.orderId, patientEmail: this.order.patientEmail, shippingDate: this.shippingDateInputValue };

    this.queriesService.updateShippingDate(data).subscribe({
      next: (res) => {
        this.toastr.success('Shipping number updated successfully');
        this.spinner.hide('updatingOrder');
        this.order.shippingDate = this.shippingDateInputValue;
      },
      error: (err) => {
        this.spinner.hide('updatingOrder');
        this.toastr.error('Error updating shipping date');
      }
    });
  }
  deliveryDateInputChange() {
    this.showSubmit = this.deliveryDateInputValue.trim().length > 0;
  }
  saveDeliveryDate() {
    const data = { id: this.order.id, orderId: this.order.orderId, patientEmail: this.order.patientEmail, deliveryDate: this.deliveryDateInputValue };

    this.queriesService.updateDeliveryDate(data).subscribe({
      next: (res) => {
        this.toastr.success('Delivery updated successfully');
        this.spinner.hide('updatingOrder');
        this.order.deliveryDate = this.deliveryDateInputValue;
      },
      error: (err) => {
        this.spinner.hide('updatingOrder');
        this.toastr.error('Error updating delivery date');
      }
    });
  }

  onStatusInputChange() {
    this.showStatusSubmit = this.orderStatusInputValue.trim().length > 0;
  }
  onInvoiceIdChange() {
    this.showQBInvoiceIdSubmit = this.qbInvoiceInputValue.trim().length > 0;
  }
  saveTrackingNumber() {
    this.spinner.show('updatingOrder');
    const data = { id: this.order.id, orderId: this.order.orderId, patientEmail: this.order.patientEmail, trackingNumber: this.trackingInputValue };

    this.queriesService.updateTrackingNumber(data).subscribe({
      next: (res) => {
        this.toastr.success('Tracking number updated successfully');
        this.spinner.hide('updatingOrder');
        this.order.tracking_number = this.trackingInputValue;
      },
      error: (err) => {
        this.spinner.hide('updatingOrder');
        this.toastr.error('Error updating tracking number');
      }
    });
  }

  saveInvoiceId() {
    this.spinner.show('updatingOrder');
    const data = { id: this.order.id, orderId: this.order.orderId, patientEmail: this.order.patientEmail, invoiceId: this.qbInvoiceInputValue };

    this.queriesService.updateInvoiceId(data).subscribe({
      next: (res) => {
        this.toastr.success('Invoice ID updated successfully');
        this.spinner.hide('updatingOrder');
        this.order.invoiceId = this.qbInvoiceInputValue;
      },
      error: (err) => {
        this.spinner.hide('updatingOrder');
        this.toastr.error('Error updating invoice ID');
      }
    });
  }

}
