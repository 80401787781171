
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { NavbarComponent } from "./navbar/navbar.component";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatMenuModule } from '@angular/material/menu';
import { UserformComponent } from './userform/userform.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SidebarComponent } from "./sidebar/sidebar.component";
import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';
import { FooterComponent } from "./footer/footer.component";
import { PatientNavbarComponent } from './patient-navbar/patient-navbar.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { ClipboardModule } from "@angular/cdk/clipboard";
import { TableModule } from 'primeng/table';
import { InputMaskModule } from 'primeng/inputmask';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { ButtonModule } from "primeng/button";

@NgModule({

  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxSpinnerModule,
    MatMenuModule,
    TablerIconsModule.pick(TablerIcons),
    NgbModule,
    ClipboardModule,
    TableModule,
    InputMaskModule,
    InputGroupModule,
    InputGroupAddonModule,
    ButtonModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    UserformComponent,
    PatientNavbarComponent,
    OrderDetailsComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    UserformComponent,
    PatientNavbarComponent,
    OrderDetailsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {

}
