<div class="logo">
  <img src="../../../assets/medsdirect-horizontal.png" />

</div>
<div class="sidebar-wrapper">
  <ul class="nav">
    <ng-container *ngFor="let menuItem of menuItems">
      <li routerLinkActive="active" *ngIf="userCanView(menuItem)" class="{{menuItem.class}} nav-item">
        <a [routerLink]="[menuItem.path]">
          <i class="now-ui-icons {{menuItem.icon}}"></i>
          <p>{{menuItem.title}}</p>
        </a>
      </li>
    </ng-container>
  </ul>
</div>
